import React from "react";
import { css } from "goober";

import useChampionsPatchChange from "@/game-lol/utils/useChampionsPatchChange.jsx";
import { getAllPatchesForChampions } from "@/game-lol/utils/util.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";

export default function HomeStatisticsTile() {
  const allPatches = getAllPatchesForChampions();
  const { rows, cols } = useChampionsPatchChange(allPatches);

  return (
    <GameGuideTileLg
      title={["common:navigation.statistics", "Statistics"]}
      description={[
        "home:guides.lol.statistics.description",
        "Optimize your League of Legends gameplay with our comprehensive champion overview. Discover champion statistics, guides, builds, and more!",
      ]}
      buttonText={["common:navigation.viewStatistics", "View Statistics"]}
      href={"/lol/champions/overview"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="champion-meta">{cols[1].display}</div>
          <div className="stat">{cols[2].display}</div>
          <div className="stat">{cols[3].display}</div>
          <div className="stat">{cols[4].display}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body numRows={5}>
          {(rows || []).map((row, i) => {
            if (!row) return null;
            return (
              <GameGuideTable.Row key={i} link={row[1].link}>
                <div className="champion-meta">
                  <div className="role-icon">{row[0].display}</div>
                  {row[1].display}
                </div>
                <span className="stat" style={row[2].style}>
                  {row[2].display}
                </span>
                <span className="stat">{row[3].display}</span>
                <span className="stat pickrate">{`+ ${row[4].display}`}</span>
              </GameGuideTable.Row>
            );
          })}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

const Table = () => css`
  .table-row:hover {
    .role-icon {
      background: var(--shade7);
    }
  }

  .champion-meta {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);

    .role-icon {
      position: absolute;
      z-index: 1;
      top: var(--sp-5);
      left: var(--sp-5);
      padding: var(--sp-0_5);
      background: var(--card-surface);
      border-radius: var(--br-sm);

      svg {
        height: var(--sp-4) !important;
        width: var(--sp-4) !important;
      }

      @container home-table (inline-size <= 400px) {
        position: unset;
        background: transparent;
      }
    }
  }
  .champion-img {
    width: var(--sp-9);
    height: var(--sp-9);

    @container home-table (inline-size <= 400px) {
      width: var(--sp-7);
      height: var(--sp-7);
    }
  }
  .champion-name {
    max-width: 11ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;

    @container home-table (inline-size <= 400px) {
      width: 20%;
    }
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
  .pickrate {
    @container home-table (inline-size <= 400px) {
      display: none;
    }
  }
`;
